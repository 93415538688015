import { default as fetch } from "./configure";

export default {
  // params -> { token }
  getCategory: (params) => fetch.get(`/services/app-qa-category`, { params }),

  // params -> { page, limit }
  getList: (params) => fetch.get(`/services/app-qa-boards`, { params }),

  getView: (id) => fetch.get(`/services/app-qa-boards/${id}`),

  postWrite: (body) => fetch.post(`/services/app-qa-boards`, body),

  putUpdate: (id, body) => fetch.put(`/services/app-qa-boards/${id}`, body),

  remove: (id) => fetch.delete(`/services/app-qa-boards/${id}`),

  upload: (body) =>
    fetch.post("https://file.school-mom.kr/uploadFile.php", body),
};
