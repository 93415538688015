import { reactive } from "vue";
import { v4 as uuidv4 } from "uuid";

export const state = reactive({
  alerts: [],

  confirmTimer: null,
  confirm: {
    toggle: false,
    info: {
      title: null,
      content: null, // html
      onConfirm: null,
      onCancle: null,
    },
    options: {
      isCancle: true, // 취소 가능 여부
      buttonText: {
        confirm: "확인",
        cancle: "취소",
      },
    },
  },
});

export const mutations = {
  // alert
  add({ type, message }) {
    const uid = uuidv4();
    if (state.alerts.length > 5) {
      const alert = state.alerts[state.alerts.length - 1];
      this.remove(alert.uid);
    }

    state.alerts.unshift({ uid, type, message });
  },

  // alert
  remove(uid) {
    const index = state.alerts.findIndex((v) => v.uid === uid);
    state.alerts.splice(index, 1);
  },

  // confirm
  open(info = {}, options = {}) {
    if (state.confirmTimer) {
      clearTimeout(state.confirmTimer);
    }

    state.confirm.toggle = true;
    state.confirm.info = {
      ...state.confirm.info,
      ...info,
      onCancle: () => {
        if (info.onCancle && typeof info.onCancle === "function") {
          info.onCancle();
        }

        state.confirm.toggle = false;
        this.close();
      },
      onConfirm: () => {
        if (info.onConfirm && typeof info.onConfirm === "function") {
          info.onConfirm();
        }

        state.confirm.toggle = false;
        this.close();
      },
    };
    state.confirm.options = { ...state.confirm.options, ...options };
  },

  // confirm
  close() {
    state.confirmTimer = setTimeout(() => {
      state.confirm.info = {
        title: null,
        content: null, // html
        onConfirm: null,
        onCancle: null,
      };

      state.confirm.options = {
        isCancle: true, // 취소 가능 여부
        buttonText: {
          confirm: "확인",
          cancle: "취소",
        },
      };
    }, 1500);
  },
};
