import axios from "axios";
import { hasOwnProperty } from "../utils/lib";

const baseURL = "https://api.schoolt.net/admin";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    common: {
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      Authorization: localStorage.getItem("session"),
    },
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    const {
      data: { error, result, ...rest },
    } = response;

    if (!hasOwnProperty(response.data, "result")) {
      return response;
    }

    if (error === "no token" || error === "jwt expired") {
      history.push("/fail");
    }

    if (result === "fail") throw new Error(fail_status[error]);

    return { ...rest }; // recieve success
  },
  function (error) {
    const check =
      hasOwnProperty(error.response, "data") &&
      hasOwnProperty(error.response.data, "error");

    if (check) {
      const errorMessage = error.response.data.error;
      error.message = fail_status[errorMessage]
        ? fail_status[errorMessage]
        : "등록되지 않은 오류 입니다.";
    } else {
      error.message = "알 수 없는 오류";
    }

    return Promise.reject(error);
  }
);

export const setHeaders = (session) =>
  new Promise((resolve) => {
    axiosInstance.defaults.headers["Authorization"] = session;
    resolve(true);
  });

export const fail_status = {
  "jwt expired": "토큰이 만료되었습니다.",
  "no token": "토큰이 만료되었습니다.",
  not_found_user: "존재하지 않는 사용자입니다.",
  not_found_qa: "존재하지 않는 문의 입니다.",
  invalid_qa_id: "존재하지 않는 문의 입니다.",
  invalid_category_text: "평점내용을 선택 및 작성해주세요.",
  invalid_session: "세션이 만료되었습니다.",
  invalid_rating: "점수를 작성해주세요.",
  invalid_type: "유형을 선택해 주세요",
  must_approval_school: "결재가 필수적인 학교입니다.",
  not_correct_info: "",
  not_same_sender: "보낸사람과 일치하지 않습니다",
};

export default axiosInstance;
