import { mutations } from "./store";
import StAlert from "./StAlert";
import StConfirm from "./StConfirm";

export default {
  computed: {
    $alert() {
      return {
        success(message) {
          mutations.add({ type: "success", message });
        },

        error(message) {
          mutations.add({ type: "error", message });
        },
      };
    },
  },
  methods: {
    $confirm(info, options) {
      mutations.open(info, options);
    },
  },
  components: {
    StAlert,
    StConfirm,
  },
};
