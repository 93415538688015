export const hasOwnProperty = (obj, key) =>
  Object.prototype.hasOwnProperty.call(obj, key);

export const isNull = (value) => typeof value === "object" && value === null;

export const isEmpty = (value) =>
  value === undefined || value === "" || isNull(value) || value === "null";

export const encodeObject = (obj) => {
  const not_include_null = {};

  Object.keys(obj).forEach((key) => {
    if (!isEmpty(obj[key])) {
      not_include_null[key] = obj[key];
    }
  });

  return not_include_null;
};

export const encodeQuery = (obj) => {
  let query = "";
  const pure = encodeObject(obj);
  const length = Object.keys(pure).length - 1;

  Object.keys(pure).forEach((key, index) => {
    let param = encodeURIComponent(pure[key]);

    if (length === index) {
      query += `${key}=${param}`;
    } else {
      query += `${key}=${param}&`;
    }
  });

  return query;
};
