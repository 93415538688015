import { createStore } from "vuex";
import dayjs from "dayjs";
import router from "../router";

import { default as api } from "../api";
import { setHeaders } from "../api/configure";

export default createStore({
  state: {
    // user
    user: null,
    // category
    categories: [],
    // list
    list: [],
    count: 0,
    page: 1,
    limit: 30,
    loading: false,
    view_data: null,
  },
  mutations: {
    SET_CATEGORY(state, payload) {
      state.categories = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_LIST(state, payload) {
      if (payload.count === 0) return;

      const nData = payload.data.map(
        ({ category_id, created_at, ...rest }) => ({
          ...rest,
          created_at: dayjs(created_at).format("YYYY년 MM월 DD일 H시 m분"),
          category: state.categories.find(({ id }) => id === category_id),
        })
      );

      state.list = nData;
      state.count = payload.count;
    },

    SET_LIST_CLEAR(state) {
      state.list = [];
      state.count = 0;
      state.view_data = null;
    },

    SET_APPEND_LIST(state, payload) {
      const nData = payload.data.map(
        ({ category_id, created_at, ...rest }) => ({
          ...rest,
          created_at: dayjs(created_at).format("YYYY년 MM월 DD일 H시 m분"),
          category: state.categories.find(({ id }) => id === category_id),
        })
      );

      state.list = [...state.list, ...nData];
    },

    SET_PAGE(state, payload) {
      state.page = payload;
    },

    SET_EDIT_VIEW_DATA(state, payload) {
      state.view_data = payload;
    },

    TOGGLE_LOADING(state) {
      state.loading = !state.loading;
    },
  },
  actions: {
    // category + user
    async GET_ASYNC_CATEGORY(
      { state, commit, dispatch },
      payload = { token: null }
    ) {
      const { token } = payload;

      if (state.user) {
        return;
      }

      try {
        const response = await api.getCategory({ token });
        const { data, accessToken, user_info } = response;
        setHeaders(accessToken);

        commit("SET_CATEGORY", data);
        commit("SET_USER", user_info);
        dispatch("GET_ASYNC_LIST");

        localStorage.setItem("token", token);
        localStorage.setItem("session", accessToken);

        if (router.currentRoute.value.path === "/") {
          router.push("/list");
        }
      } catch (error) {
        router.push("/non-user");
        // console.log(error);
      }
    },

    async GET_MORE_ASYNC_LIST({ state, commit }) {
      const page = state.page + 1;

      if (state.count !== state.list.length) {
        commit("TOGGLE_LOADING");

        try {
          const response = await api.getList({
            page,
            limit: state.limit,
          });

          commit("SET_PAGE", page);
          commit("TOGGLE_LOADING");
          commit("SET_APPEND_LIST", response);
        } catch (error) {
          commit("TOGGLE_LOADING");
          console.log(error);
        }
      }
    },

    async GET_ASYNC_LIST({ state, commit }) {
      if (state.count === 0) {
        commit("TOGGLE_LOADING");

        try {
          const response = await api.getList({
            page: state.page,
            limit: state.limit,
          });

          commit("TOGGLE_LOADING");
          commit("SET_LIST", response);
        } catch (error) {
          commit("TOGGLE_LOADING");
          console.log(error);
        }
      }
    },

    CLEAR_AND_GET_LIST({ commit, dispatch }) {
      commit("SET_LIST_CLEAR");
      dispatch("GET_ASYNC_LIST");
    },
  },
  modules: {},
});
