import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { default as api } from "./api";

import "./styles/global.scss";

import Tag from "./components/Tag";
import DateComponent from "./components/Date";

import uiMixin from "./ui";

const app = createApp(App);

app.config.globalProperties.$api = api;
app.use(store).use(router).mixin(uiMixin).mount("#app");
app.component("st-tag", Tag);
app.component("st-date", DateComponent);
