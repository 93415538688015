<template>
  <div class="app-wrap">
    <loading-bar />
    <router-view :key="$route.fullPath" />
    <st-alert />
    <st-confirm />
  </div>
</template>

<script>
import LoadingBar from "./components/LoadingBar";
export default {
  name: "App",
  components: {
    LoadingBar,
  },
};
</script>
