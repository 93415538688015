<template>
  <span class="tag" :color="color">
    <slot />
  </span>
</template>

<script>
export default {
  name: "Tag",
  props: {
    color: String,
  },
};
</script>

<style lang="scss">
@import "~@/color";

span.tag {
  font-size: 1.2rem;
  padding: 3px 9px;
  border-radius: 2px;
  display: inline-block;
  background-color: $violet01;
  color: white;

  &[color="unactive"] {
    background-color: $gray02;
    color: $gray05;
  }

  &[color="active"] {
    background-color: $primary;
  }
}
</style>
