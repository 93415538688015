<template>
  <st-modal v-model:value="data.toggle" :title="data.info.title">
    <div class="st-confirm">
      {{ data.info.content }}
    </div>
    <template v-slot:footer>
      <div class="confirm-footer">
        <button class="cancle" @click="onClose">취소</button>
        <button class="confirm" @click="onConfirm">확인</button>
      </div>
    </template>
  </st-modal>
</template>

<script>
import StModal from "./StModal.vue";
import { state } from "./store";

export default {
  name: "ui.StConfirm",
  components: {
    StModal,
  },
  computed: {
    data() {
      return state.confirm;
    },
  },
  methods: {
    onClose() {
      state.confirm.info.onCancle();
    },

    onConfirm() {
      state.confirm.info.onConfirm();
    },
  },
};
</script>

<style lang="scss">
@import "~@/color";

.st-confirm {
  font-size: 15px;
}

.confirm-footer {
  display: flex;
  justify-content: flex-end;

  button {
    width: 60px;
    height: 36px;
    font-weight: 500;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    & + button {
      margin-left: 10px;
    }

    &.cancle {
      color: $gray06;
    }

    &.confirm {
      color: $violet01;
    }
  }
}
</style>
