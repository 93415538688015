<template>
  <div class="st-modal-header">
    <div class="st-modal-title">
      <slot name="title">
        <span>{{ title }}</span>
      </slot>
    </div>

    <span class="st-modal-close" @click="$emit('close')">
      <!-- <fa-icon :icon="['fal', 'times']" /> -->
    </span>
  </div>
</template>

<script>
export default {
  name: "ui.StModal.Header",
  props: {
    title: String,
  },
};
</script>

<style lang="scss">
@import "~@/color";

.st-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  height: 40px;

  .st-modal-title {
    font-size: 16px;
    font-weight: 600;
  }

  .st-modal-close {
    cursor: pointer;
    padding: 0 10px;

    svg {
      font-size: 22px;
    }
  }
}
</style>
