<template>
  <div class="alert-wrap">
    <ul class="alert-items">
      <alert-item
        v-for="(alert, index) in alerts"
        :key="alert.uid"
        :uid="alert.uid"
        :type="alert.type"
        :index="index"
        @remove="remove"
      >
        {{ alert.message }}
      </alert-item>
    </ul>
  </div>
</template>

<script>
import AlertItem from "./components/AlertItem.vue";
import { state, mutations } from "./store";

export default {
  name: "ui.StAlert",
  components: { AlertItem },
  computed: {
    alerts() {
      return state.alerts;
    },
  },
  methods: {
    remove(uid) {
      mutations.remove(uid);
    },
  },
};
</script>

<style lang="scss">
.alert-wrap {
  position: fixed;
  top: 14px;
  z-index: 10;
  left: 0;
  right: 0;
}

.alert-items {
  position: absolute;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  min-width: 320px;
  max-width: 90%;
}
</style>
