<template>
  <transition name="slideup">
    <div class="bar" v-if="loading"></div>
  </transition>
</template>

<script>
export default {
  name: "LoadingBar",
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>

<style lang="scss">
@import "~@/color";

.bar {
  position: absolute;
  background-color: $violet00;
  height: 6px;
  top: 0;
  left: 0;
  right: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    background-color: $violet01;
    height: 6px;
    width: 100%;
    animation: slide 3s infinite linear;
  }
}

.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.35s;
}

.slideup-enter,
.slideup-leave-to {
  transform: translateY(-6px);
}

@keyframes slide {
  0% {
    left: -100%;
  }

  50% {
    left: 0%;
  }

  100% {
    left: 100%;
  }
}
</style>
