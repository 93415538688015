<template>
  <transition name="fade" mode="out-in">
    <div class="st-modal-wrapper" v-if="value" key="modal">
      <div class="st-modal-bg" @click="onModalClose" />
      <div class="st-modal">
        <div class="st-modal-container">
          <slot name="header">
            <modal-header :title="title" @close="onModalClose" />
          </slot>
          <div class="st-modal-content">
            <slot />
          </div>
          <slot name="footer"> </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalHeader from "./components/ModalHeader.vue";

export default {
  name: "ui.StModal",
  components: {
    ModalHeader,
  },
  props: {
    value: { type: Boolean, default: () => false },
    title: String,
  },
  computed: {},
  methods: {
    onModalClose() {
      this.$emit("update:value", !this.value);
    },
  },
};
</script>

<style lang="scss">
.st-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;

  .st-modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 10002;
  }

  .st-modal {
    position: absolute;
    background-color: white;
    top: 5vh;
    left: 50%;
    transform: translateX(-50%) translateY(0px);
    min-width: 320px;
    max-width: 85%;
    z-index: 10003;
    border-radius: 6px;
    transition: all 0.4s;
    overflow: hidden;

    &-container {
      padding: 16px 20px;
      max-height: 90vh;
      overflow: hidden;
    }

    &-content {
      display: block;
      max-width: 90vw;
      max-height: calc(90vh - 88px); // title + padding top|bottom
      padding: 4px 0 20px;
      overflow-y: auto;
      word-break: break-all;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.35s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  visibility: visible;

  .st-modal {
    transform: translateX(-50%) translateY(80px);
  }
}
</style>
