<template>
  <li :class="`alert-item ${type}`" :style="style">
    <slot />
  </li>
</template>

<script>
export default {
  name: "AlertItem",
  data: () => ({
    timer: null,
  }),
  props: {
    type: String,
    uid: String,
    index: Number,
  },
  computed: {
    style() {
      const top = (48 + 10) * this.index;
      return `top: ${top}px`;
    },
  },
  mounted() {
    if (this.timer === null) {
      this.timer = setTimeout(() => {
        this.timer = null;
        setTimeout(() => {
          this.$emit("remove", this.uid);
        });
      }, 3000);
    }
  },
};
</script>

<style lang="scss">
@keyframes visible {
  0% {
    top: -60px;
  }
  100% {
    top: 0;
  }
}

li.alert-item {
  position: absolute;
  font-size: 13px;
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  border-radius: 4px;
  width: 100%;
  transition: all 0.25s ease-out;
  animation: visible 0.25s ease-out;

  &.success {
    background-color: #3fff83;
  }

  &.error {
    background-color: #ff5454;
    color: white;
  }
}
</style>
