<template>
  <span :class="`date ${isBeforeLine ? 'line' : ''}`">
    <slot />
  </span>
</template>

<script>
export default {
  name: "Date",
  props: {
    isBeforeLine: Boolean,
  },
};
</script>

<style lang="scss">
@import "~@/color";

span.date {
  position: relative;
  font-size: 1.5rem;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  color: #838383;
  letter-spacing: -0.4px;

  &.line {
    padding-left: 14px;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 4px;
      height: 100%;
      left: 0;
      background-color: #838383;
    }
  }
}
</style>
