import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

const routes = [
  {
    name: "Root",
    path: "/",
    component: () => import("../views/Root.vue"),
    children: [
      {
        path: "/list",
        name: "List",
        component: () => import("../views/List.vue"),
      },
      {
        path: "/view/:id?",
        name: "View",
        component: () => import("../views/View.vue"),
      },
      {
        path: "/write/:id?",
        name: "Write",
        component: () => import("../views/Write.vue"),
      },
      {
        path: "/non-user",
        name: "NonUser",
        component: () => import("../views/NonUser.vue"),
      },
      {
        path: "/404",
        name: "NotFound",
        component: () => import("../views/404.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

export default router;
